<template>
  <div class="view-terms py-5 text-light">
    <div class="container pb-5">
      <h1 class="text-center mb-5 px-5">
        <router-link :to="{ name: 'index' }">
          <img class="img-fluid w-25" src="@/assets/images/logo.png" />
        </router-link>
      </h1>
      <h1 class="mb-5">Termos legais</h1>

      <div class="text-secondary">
        <h3><strong>INFORMAÇÕES PRELIMINARES</strong></h3>

        <p>
          Este site e todo o seu conteúdo (o “<em>WEBSITE</em>”) são de
          propriedade exclusiva de&nbsp;WLR CURSOS E EVENTOS LTDA., inscrita no
          CNPJ sob o n. 32.816.941/0001-94, com sede em Avenida Brasil, n° 6141,
          Zona 05, CEP: 87.015-280, Maringá, Paraná (“FORNECEDORA”), detentora
          dos endereços eletrônicos
          <a href="http://www.italomarsili.com.br">www.italomarsili.com.br</a> e
          www.guerrilhaway.com.br (“WEBSITES”) e subendereços.
        </p>

        <ol>
          <li>
            O USUÁRIO é a pessoa física ou jurídica que se cadastrou no
            <em>WEBSITE </em>ou que adquiriu produtos e serviços através do
            <em>WEBSITE</em>, pelo que reconhece e aceita os presentes Termos de
            Uso como Contrato por meio eletrônico.
          </li>
        </ol>

        <p>
          <strong>Atenção</strong>! Ao acessar o <em>WEBSITE </em>e/ou ao
          efetuar a aquisição de qualquer produto ou serviço através do mesmo,
          você está se declarando ciente e concordando de forma irrestrita,
          irrenunciável e irretratável, com o inteiro teor deste contrato
          digital (TERMOS DE USO) e com nossa Política de Privacidade.
        </p>

        <p>
          Caso você discorde de qualquer parte deste termo, você não deve
          acessar este site nem adquirir qualquer produto ou serviço aqui
          relacionado.
        </p>

        <ul>
          <li>
            Eventual tolerância da FORNECEDORA quanto ao descumprimento de
            qualquer obrigação prevista em seus Termos de Uso, contratos e na
            Política de Privacidade do <em>WEBSITE</em> não implica em novação
            nem tampouco em renúncia ao direito de exigir o cumprimento da
            obrigação.
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Importante! Alguns produtos digitais, serviços e/ou conteúdos
              oferecidos pela FORNECEDORA são objeto de contratos específicos,
              devendo o presente instrumento, nestes casos em específico, ser
              tratado como suplementar. Em não havendo contrato específico
              apresentado previamente para o USUÁRIO para determinado produto
              digital, serviço e/ou conteúdo, valerão as regras gerais
              especificadas no respectivo contrato-tipo, conforme a modalidade
              “curso livre”, “imersão” ou “mentoria”, anexos e partes
              integrantes dos presentes TERMOS LEGAIS para todos os fins de
              direito.</strong
            >
          </li>
        </ul>

        <h3><strong>USO DO SITE </strong></h3>

        <ul>
          <li>
            Ao usar o <em>WEBSITE</em>, você está ciente de que o conteúdo aqui
            disponibilizado, seja por meio escrito ou qualquer forma de mídia,
            inclusive imagens, vídeos e voz, são fruto do intelecto dos
            profissionais da FORNECEDORA e, portanto, constituem patrimônio
            intelectual da empresa e/ou dos mesmos.
          </li>
        </ul>

        <ul>
          <li>
            A disponibilização do conteúdo para o usuário final, seja ela de
            forma gratuita ou paga, jamais se dá por venda e compra ou outra
            forma de transferência definitiva de propriedade.&nbsp;&nbsp;
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Na aquisição de qualquer direito, produto, infoproduto ou
              serviços da FORNECEDORA, o USUÁRIO se vincula ao respectivo
              contrato, parte integrante dos presentes “termos legais” e abaixo
              apresentados na íntegra.</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            O USUÁRIO, portanto, declara-se ciente de que ao acessar o conteúdo
            do <em>WEBSITE</em> o está fazendo mediante mera cessão, de natureza
            precária e temporária, de direito de uso. Por este motivo lhe é
            vedado fazer <em>download</em> de vídeos e/ou áudios, copiar e/ou
            plagiar conteúdo, efetuar engenharia reversa, alterar, suprimir,
            modificar ou inutilizar, de qualquer maneira, dispositivos técnicos
            introduzidos nos exemplares das obras e produções para evitar ou
            restringir sua cópia, e/ou fazer <em>download</em> de material não
            disponibilizado efetivamente para esta finalidade, sob pena de arcar
            com os danos patrimoniais, lucros cessantes, danos morais por uso
            indevido de imagem, voz e /ou propriedade intelectual sem prejuízo
            de outras sanções, inclusive de natureza penal.
          </li>
        </ul>

        <ul>
          <li>
            A proibição se estende ainda que o ato vise obter cópia para uso
            exclusivo pessoal.
          </li>
        </ul>

        <ul>
          <li>
            O USUÁRIO não pode usar nenhum dado presente no site com finalidade
            de publicidade ou propaganda, direta ou indiretamente, ainda que de
            forma gratuita e sem finalidade comercial, sem a prévia solicitação
            e consentimento da&nbsp;FORNECEDORA.
          </li>
        </ul>

        <ol>
          <li>
            Toda e qualquer aquisição de direito de uso dos produtos e/ou
            serviços da FORNECEDORA será realizada por meio de plataformas
            financeiras especializadas em pagamentos eletrônicos e
            intermediação, tais como, mas não limitadas, a <em>Hotmart</em> e
            <em>Eduzz</em> as quais possuem regulamentos, termos de uso e
            políticas próprias, que devem ser consideradas e avaliadas em seus
            respectivos âmbitos de atuação e sob responsabilidade exclusiva
            daquelas empresas. Não recomendamos que o USUÁRIO efetive qualquer
            negócio, ou adquira qualquer produto ou serviço caso discorde do
            todo ou em parte dos regulamentos, termos de uso e/ou das políticas
            das plataformas de pagamento.
          </li>
        </ol>

        <ol>
          <li>
            Após o cadastro, o USUÁRIO passa a receber com prioridade o nosso
            conteúdo, no entanto, tem toda a liberdade de se retirar a partir do
            momento que não quiser mais fazer parte da nossa lista. Essa opção
            estará disponível em um <em>link</em> no rodapé dos próprios
            <em>e-mails</em> que enviarmos a você, ou diretamente pelo meio de
            contato disponível no <em>WEBSITE</em>.
          </li>
        </ol>

        <h3><strong>PROPRIEDADE INTELECTUAL</strong></h3>

        <ol>
          <li>
            Todos os direitos relativos a este <em>WEBSITE</em> são reservados
            à&nbsp;FORNECEDORA, inclusive, mas não se limitando a textos,
            imagens, áudios, vídeos e quaisquer outros conteúdos. Todas as
            marcas aqui apresentadas são da FORNECEDORA, ou de empresas
            parceiras, afiliadas, fornecedoras ou referem-se a produtos para os
            quais possuímos a devida licença comercialização.
          </li>
        </ol>

        <ol>
          <li>
            <strong
              >É terminantemente proibido ao usuário do site: reproduzir,
              utilizar, copiar, distribuir, permitir o acesso público, efetuar
              aquisição do direito de acesso a qualquer curso ou serviço de
              forma coletiva (“<em>groupbuy</em>”), disponibilizar nossos
              conteúdos de forma gratuita ou onerosa ao público em geral,
              transformar, plagiar, ou modificar de quaisquer formas os
              conteúdos da FORNECEDORA, a menos que possua prévia autorização
              por escrito do titular dos correspondentes direitos.</strong
            >
          </li>
        </ol>

        <h3><strong>LIMITAÇÃO DE RESPONSABILIDADE</strong></h3>

        <ol>
          <li>
            É vedado ao USUÁRIO que discorde parcial ou integralmente da
            política de privacidade ou dos termos de uso aqui veiculados acessar
            o conteúdo do <em>WEBSITE</em>.
          </li>
        </ol>

        <ol>
          <li>
            Tendo em vista as características inerentes ao ambiente da
            <em>internet</em>,é possível que ocorram interrupções ou suspensões
            de conexão, transmissões de computador incompletas ou que venham a
            falhar, bem como por falha técnica de qualquer tipo, incluindo, mas
            não se limitando, ao mau funcionamento eletrônico de qualquer rede,
            “hardware” ou “software” ou mesmo equipamento do USUÁRIO.
          </li>
        </ol>

        <h3><strong>CONTATO</strong></h3>

        <ol>
          <li>
            Todo contato por parte de usuários da&nbsp;FORNECEDORA deverá
            ocorrer através do canal de contato disponível no <em>WEBSITE</em>.
          </li>
        </ol>

        <h3><strong>DISPOSIÇÕES GERAIS</strong></h3>

        <ol>
          <li>
            Qualquer dúvida ou solicitações relacionadas a estes Termos de Uso,
            contratos ou à Política de Privacidade deverão ser apresentadas
            através do canal de contato disponível no <em>WEBSITE</em>.
          </li>
        </ol>

        <ol>
          <li>
            Para dirimir de forma definitiva quaisquer dúvidas relativas aos
            termos de uso ou política de privacidade, bem como uso do site ou
            infração à direitos de propriedade intelectual, fica eleito o foro
            de Rio de Janeiro/RJ, em detrimento de qualquer outro por mais
            privilegiado que seja.
          </li>
        </ol>

        <h2>
          Anexo I – Contrato de Cessão de Direitos de Uso de Infoproduto (Cursos
          Livres)
        </h2>

        <p>São partes deste instrumento:</p>

        <p>
          De um lado, a FORNECEDORA identificada em epígrafe, detentora dos
          <em>WEBSITES</em> e subendereços, e de outro lado, o Aluno/Usuário
          e/ou Responsável Financeiro, aderente aos presentes termos e condições
          gerais de prestação de serviços educacionais, devidamente identificado
          através das informações prestadas à plataforma de pagamentos
          (“ALUNO”).
        </p>

        <h3><strong>Cláusula Primeira – Do Objeto</strong></h3>

        <p>
          Este contrato tem como objeto a cessão de direito de uso de conteúdo
          de curso livre (“CURSO”) cujos direitos autorais são de propriedade da
          FORNECEDORA.
        </p>

        <p>
          § 1º. O CURSO é ofertado na modalidade <em>online</em>, inserido na
          PLATAFORMA de ensino à distância disponível em endereço eletrônico
          onde o ALUNO tem acesso às suas informações acadêmicas e
          administrativas.
        </p>

        <p>
          § 2º. O acesso do ALUNO ao curso ora contratado será de 12 (doze)
          meses a contar da cessão.
        </p>

        <p>
          § 3º.
          <strong
            >O ALUNO declara-se ciente e concorda que o uso do curso não guarda
            relação com o número de parcelas a pagar para financiar o curso,
            podendo este ser integralmente cursado em prazo inferior ou superior
            ao prazo de financiamento. A conclusão do CURSO não será considerada
            motivo para interromper o pagamento de quaisquer parcelas do
            financiamento escolhido pelo ALUNO; da mesma forma o fato de o
            ALUNO, por sua exclusiva responsabilidade, ter deixado de assistir
            ao curso no todo ou em parte ao longo do período em que para ele se
            encontra disponibilizado, não é motivo para ressarcimento de
            valores.
          </strong>
        </p>

        <p>
          § 4º.
          <strong>O ALUNO reconhece, expressamente, compreender que:</strong>
        </p>

        <ol>
          <li>
            <strong
              >Não se trata de um contrato de prestação continuada, com execução
              diferida no tempo, em que ao consumidor é dado desistir de sua
              continuação deixando de pagar as parcelas vencíveis.</strong
            >
          </li>
        </ol>

        <ol>
          <li>
            <strong
              >Trata-se de uma cessão de direito de uso deinfoproduto,
              consubstanciado em conteúdo audiovisual e material escrito,
              fornecido integralmente à consumidora conforme cronograma
              pedagógico, com preço uno, cujo pagamento pode ser parcelado pelo
              ALUNO junto à sua administradora de cartão de crédito, ou mesmo
              utilizando de mecanismos de “recorrência” para administração de
              limite de crédito.</strong
            >
          </li>
        </ol>

        <ol>
          <li>
            <strong
              >Com a entrega do infoproduto à consumidora (consubstanciado pelo
              acesso à plataforma de uso do conteúdo do curso adquirido), e
              superados os prazos legal e contratual para a desistência do
              negócio pelo ALUNO, não é obrigada a FORNECEDORA a receber de
              volta de forma antecipada o CURSO fornecido nem a proceder com a
              devolução de valores já recebidos.</strong
            >
          </li>
        </ol>

        <ol>
          <li>
            <strong
              >É inaplicável ao presente contrato o instituto do “cancelamento
              de serviço”, porque não se trata de serviço em sua essência, mas
              de produto – intangível dada a sua natureza eletrônica e
              intelectual – mas que como qualquer outro produto, uma vez
              entregue, e tendo sido comercializado à distância, possui o
              consumidor o direito de dele testar para, no prazo legal ou
              contratualmente diferido, refluir de sua decisão recebendo de
              volta o valor pago, ou confirmá-la tacitamente.</strong
            >
          </li>
        </ol>

        <ul>
          <li>
            <strong
              >A eventualidade de o ALUNO ter escolhido o parcelamento do preço
              ajustado não interfere no fato de ainda ser devido, integralmente,
              o preço do produto adquirido, quando verificado que não possui
              mais direito à desistência do contrato com restituição das
              importâncias pagas e devolução da coisa.</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >A tomada de medidas unilaterais pelo ALUNO junto à administradora
              de cartão de crédito, instituição bancária, ou de qualquer outra
              forma, que interrompa o pagamento dos valores devidos à
              FORNECEDORA ensejará a tomada das medidas judiciais cíveis
              necessárias para a cobrança das quantias impagas, acrescidas das
              penalidades contratualmente estabelecidas e despesas processuais,
              sem prejuízo da <em>notitiacriminis</em> para apuração, pela
              autoridade policial competente, da possível ocorrência do tipo
              previsto no art. 168 do Código Penal Brasileiro.</strong
            >
          </li>
        </ul>

        <h3><strong>Cláusula Segunda – Da Cessão</strong><strong></strong></h3>

        <p>
          O ALUNO requereu a cessão dos direitos de uso do conteúdo do CURSO
          conforme dados preenchidos no <em>gateway</em> de pagamento,
          declarando previamente ter lido e aceitando integralmente todas as
          normas de Políticas, contratos e Termos de Uso disponíveis na íntegra
          no WEBSITE, bem como na legislação aplicável e as emanadas de outras
          fontes normativas, desde que regulem supletivamente a matéria.
        </p>

        <p>
          § 1º.
          <strong
            >O aluno declara que, previamente ao seu requerimento, teve acesso à
            todas as informações relativas ao CURSO, ficando desde já consignado
            que FUTURAS DÚVIDAS DEVERÃO SER TRATADAS EM LOCAL ESPECÍFICO
            disponibilizado no WEBSITE</strong
          >.
        </p>

        <p>
          § 2º.
          <strong
            >O ALUNO declara-se ciente de que tem prazo de arrependimento
            improrrogável de 7 (sete) dias contados da data do pagamento da
            cessão ou liberação de acesso ao conteúdo do CURSO na forma da
            Cláusula Terceira, <em>caput</em>, infra.</strong
          >
        </p>

        <p>
          § 3º. A efetivação da cessão, independentemente de ter transcorrido ou
          não o prazo de arrependimento supra, significa, para todos os fins,
          plena concordância com os termos e condições deste contrato e demais
          documentos mencionados na Cláusula Segunda, <em>caput</em>, supra.
        </p>

        <h3>
          <strong>Cláusula Terceira – Do Acesso ao Curso</strong
          ><strong></strong>
        </h3>

        <p>
          Após a efetivação da cessão nos moldes da cláusula segunda supra, a
          FORNECEDORA disponibilizará acesso à “Área de Alunos”, cujo ingresso
          se dará exclusivamente por meio de <em>login</em> e senha de natureza
          pessoal e intransferível.
        </p>

        <p>
          § 1º. A senha é de uso pessoal e intransferível do ALUNO, que
          responderá pela utilização indevida da mesma e por todos os danos e
          prejuízos decorrentes da disponibilização a terceiros, seja com o
          intuito de auferir lucro ou não, sendo a guarda da senha exclusiva
          responsabilidade do aluno.
        </p>

        <p>
          § 2º. A senha garantirá acesso exclusivamente ao conteúdo do CURSO
          contratado e o acesso ao CURSO para seu uso ocorrerá pelo prazo
          específico de 12 (doze) meses a contar do pagamento, findo o qual,
          será bloqueada automaticamente.&nbsp;
        </p>

        <p>
          § 3º. Não é permitido o acesso à “Área de Alunos”de forma simultânea
          em mais de um terminal (computador, <em>tablet</em>, celular, ou
          qualquer outra tecnologia).
        </p>

        <p>
          § 4º.
          <strong
            >É terminantemente vedado ao aluno, sob pena de imediato rescisão da
            cessão, com expulsão do curso sem direito à devolução de valores e
            sem prejuízo das demais medidas cabíveis no âmbito civil e
            criminal</strong
          >:
        </p>

        <ol>
          <li>Compartilhar seu <em>login</em> e senha com terceiros;</li>
        </ol>

        <ol>
          <li>
            Adquirir o curso em grupo, ou regime de “<em>groupbuy</em>”, ou
            outra forma de rateio onde várias pessoas adquirem o acesso através
            do nome de uma única pessoa compartilhando-o entre si.
          </li>
        </ol>

        <ol>
          <li>
            Fazer uso de ferramentas para gravar a tela ou para de qualquer
            forma fazer o <em>download</em> dos vídeos do curso ainda que para
            uso exclusivo pessoal.
          </li>
        </ol>

        <ol>
          <li>
            Fazer uso de ferramentas para gravar o áudio dos vídeos ou para de
            qualquer forma fazer o <em>download</em> dos áudios não
            disponibilizados especificamente para esta finalidade, ainda que
            para uso exclusivo pessoal.
          </li>
        </ol>

        <ul>
          <li>
            Fazer uso de ferramentas para fotografar a imagem da tela, inclusive
            <em>printscreens</em> de <em>smartphones</em>, ainda que para uso
            exclusivo pessoal.
          </li>
        </ul>

        <ul>
          <li>
            Compartilhar o curso, de maneira gratuita ou onerosa, em plataformas
            de venda tais como, mas não limitadas, a “Mercado Livre”, “Mercado
            Pago”, “OLX”, “<em>Whatsapp</em>” ou em qualquer tipo de mídia, vez
            que este ato constitui a prática de <strong>pirataria</strong> e
            será punido na forma da lei.
          </li>
        </ul>

        <p>
          § 5º. As aulas serão disponibilizadas de forma sequencial e
          integralmente <em>online</em>, e, para melhor aproveitamento didático
          a sequência será faseada ao longo das semanas que se sucedem à cessão.
        </p>

        <p>
          § 6º. O ALUNO declara-se ciente de que a utilização das ferramentas
          pedagógicas disponibilizadas pela FORNECEDORAao ALUNO tais como: a)
          materiais de apoio, b) aulas complementares ou de bônus; e c)
          atividades e exercícios, fazem parte do ambiente de aprendizado e
          estão sujeitas aos termos de uso, política de privacidade e política
          de direitos autorais.
        </p>

        <p>
          § 7º.
          <strong
            >O ALUNO declara-se ciente e concorda com o fato de que em hipótese
            alguma lhe está sendo comercializado qualquer direito de propriedade
            relativo ao curso. O acesso é meramente uma cessão temporária de
            direito de uso do conteúdo.</strong
          >
        </p>

        <p>
          § 8º. O ALUNO é responsável pelo sigilo de seu acesso na “Área de
          Alunos”, sendo vedada a transferência a terceiros, sob pena de
          rescisão contratual e apuração de eventual violação de direitos
          autorais. Além disso, o ALUNO compromete-se a manter seus dados
          cadastrais atualizados junto à FORNECEDORA, especialmente pelo acesso
          próprio na “Área de Alunos”(“Dados Cadastrais”).
        </p>

        <p>
          § 9º. Na participação em ferramentas pedagógicas de grupo (como
          fóruns, debates, chats etc.) ou mesmo em redes sociais, deverá o ALUNO
          manter conduta compatível com a vida acadêmica sob pena de sua atitude
          “antissocial” ser causa de exclusão da utilização da ferramenta
          específica ou mesmo a rescisão do contrato em caso de conduta grave,
          reincidente ou que atente contra a dignidade da pessoa humana, contra
          as liberdades individuais ou tenha cunho discriminatório ou obsceno.
        </p>

        <p>
          § 10. A senha garantirá acesso exclusivamente ao conteúdo do CURSO
          contratado e pelo prazo de duração previsto no mesmo, sendo bloqueada
          imediatamente após expirada.
        </p>

        <p>
          § 11. Todo conteúdo disponibilizado para o CURSO, seja por escrito,
          audiovisual ou por quaisquer outras mídias, é de propriedade exclusiva
          da FORNECEDORA e protegida pela legislação nacional e internacional de
          propriedade intelectual, ficando vedada a reprodução, transmissão,
          divulgação, armazenamento e exibição no todo ou em parte a terceiros
          que não figurem como parte deste instrumento.&nbsp;
        </p>

        <h3><strong>Cláusula Quarta – Do Preço</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          Pela prestação dos serviços educacionais objeto deste instrumento o
          ALUNO pagará àFORNECEDORA o valor descrito no requerimento de cessão,
          nos valores e vencimentos que tenha escolhido por ocasião da aquisição
          do direito de uso.
        </p>

        <p>
          § 1º. Caso a opção de pagamento tenha sido por boleto bancário ou
          depósito (se estas opções estiverem disponíveis por ocasião da
          aquisição dos direitos de cessionário), fica avençado que o atraso no
          pagamento de quaisquer parcelas será acrescido de multa moratória no
          importe de 2% (dois por cento) sobre o principal, acrescida de
          correção monetária pelo IGPM-FGV e juros de mora no importe de 1% (um
          por cento) ao mês, calculado <em>pro ratadies</em>, nos termos do
          disposto no art. 52, § 1º, do Código de Defesa do Consumidor.
        </p>

        <p>
          § 2º. Desde logo fica estabelecido que o atraso no pagamento de
          qualquer parcela ou obrigação de pagamento decorrente deste contrato
          permitirá a FORNECEDORA, cumulativamente:
        </p>

        <ol>
          <li>
            Registrar a existência do débito nos institutos de proteção ao
            crédito, tais como SPC e SERASA, dentre outros, nos termos do art.
            43, §2º, do Código de Defesa do Consumidor, sempre que o atraso de
            qualquer parcela for superior a 30 (trinta) dias.
          </li>
        </ol>

        <ol>
          <li>
            Protestar e/ou cobrar judicial ou extrajudicialmente o valor devido,
            devendo o ALUNO arcar com todas custas, despesas e encargos de
            cobrança de 10% (dez por cento), além de honorários advocatícios de
            20% (vinte por cento), em ambos os casos calculados sobre o valor
            total do débito, acrescido de custas e despesas extrajudiciais e
            judiciais.
          </li>
        </ol>

        <ol>
          <li>
            Impedir o ALUNO de usufruir de quaisquer serviços complementares
            oferecidos, incluindo, mas não se limitando ao acesso locais de
            solução de dúvidas, <em>downloads</em> etc.
          </li>
        </ol>

        <ol>
          <li>Rescindir este contrato.</li>
        </ol>

        <p>
          § 3º. Comprovado à FORNECEDORA o pagamento da(s) parcela(s)
          inadimplida(s), deverá a FORNECEDORA requerer a exclusão do
          apontamento nos cadastros dos institutos de proteção ao crédito no
          prazo máximo de 05 (cinco) dias úteis, ficando aqui esclarecido que
          após o requerimento, haverá o prazo dos próprios órgãos para efetivar
          a retirada do nome.
        </p>

        <p>
          § 4º. Qualquer desconto eventualmente concedido ao ALUNO terá caráter
          excepcional e não constituirá compromisso de concessão para outros
          cursos que o ALUNO faça ou pretenda fazer junto à FORNECEDORA.
        </p>

        <p>
          § 5º. Caso o ALUNO tenha optado pelo uso do cartão de crédito como
          forma de pagamento, este se declara já ciente que a FORNECEDORA não
          possui informações acerca da data de fechamento da fatura, e,
          portanto, não se responsabiliza pela cobrança de mais de uma parcela
          na mesma fatura, devendo o ALUNO consultar previamente a sua emissora
          do cartão de crédito.
        </p>

        <h3><strong>Cláusula Quinta – Das Obrigações do Aluno</strong></h3>

        <p>Constituem obrigações o ALUNO:</p>

        <ol>
          <li>
            Previamente à solicitação da cessãodo direito de uso do CURSO,
            analisar o descritivo do CURSO e o conteúdo a fim de avaliar se o
            mesmo é adequado aos seus interesses.
          </li>
        </ol>

        <ol>
          <li>
            Em todas as modalidades de CURSO, respeitar as normas de convívio
            social e a de conduta, tanto em relação aos demais alunos, quanto
            aos funcionários da FORNECEDORA e à própria FORNECEDORA.
          </li>
        </ol>

        <ol>
          <li>
            Para acesso ao conteúdo disponibilizado na “Área de Alunos”, possuir
            equipamentos e <em>softwares</em> ligados à <em>internet</em>.
          </li>
        </ol>

        <ol>
          <li>
            <strong>Manter</strong
            ><strong>
              seus dados cadastrais atualizados e com informações verídicas, bem
              como zelar pela confidencialidade de seu <em>login</em> e senha,
              de forma a não permitir nem possibilitar o compartilhamento com
              terceiros</strong
            >.
          </li>
        </ol>

        <ul>
          <li>
            <strong
              >Não reproduzir, sob qualquer forma, o material do CURSO, sob pena
              de responder civil e criminalmente perante a FORNECEDORA e
              terceiros, nos termos da Lei 9.609 de 19 de fevereiro de 1998, por
              violação de propriedade intelectual, devendo o uso deste ser feito
              exclusivamente em âmbito privado pelo ALUNO.
            </strong>
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Não comercializar ou ceder a qualquer título, no todo ou em
              parte, sua senha a terceiros estranhos à esta relação comercial.
            </strong>
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Não copiar por qualquer meio, inclusive <em>download</em> ou
              gravação de tela, os vídeos, áudios e documentos, devendo acessar
              o CURSO apenas de forma <em>online</em> e exclusivamente através
              da plataforma de ensino.</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            Seguir os padrões de conduta estabelecidos e vigentes na internet,
            abstendo-se de: (i) violar a privacidade dos outros usuários; (ii)
            permitir que outras pessoas utilizem seu acesso ao curso; (iii)
            utilizar qualquer técnica de invasão ao site que viole a segurança
            do ambiente de treinamento e de sites a ele relacionados; (iv) não
            agir conscientemente para destruir arquivos ou programas do ambiente
            e de sites relacionados; (v) utilizar os nomes e e-mails dos
            participantes do curso para fins comerciais; (vi) enviar mensagens
            que possam ser consideradas obscenas e/ou fora dos padrões éticos e
            de bons costumes.
          </li>
        </ul>

        <h3><strong>Cláusula Sexta – Das Obrigações da Fornecedora</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>Compete exclusivamente à FORNECEDORA:</p>

        <ol>
          <li>
            Fornecer e garantir o acesso ao CURSO desde que o ALUNO esteja em
            dia com suas obrigações e não tenha cometido falta grave que
            justifique sua expulsão.
          </li>
        </ol>

        <ol>
          <li>
            Manter corpo administrativo adequado para solucionar dúvidas
            referentes a acesso ou ao CURSO.
          </li>
        </ol>

        <ol>
          <li>
            Realizar o planejamento pedagógico incluindo, mas não se limitando à
            fixação da carga horária total do curso, designação dos instrutores,
            orientação didático-pedagógica, definição de datas de eventos se
            forem aplicáveis, distribuição das aulas e fixação do calendário das
            mesmas, além de outras providências que a atividade docente exigir.
          </li>
        </ol>

        <ol>
          <li>
            Coordenar administrativa e academicamente o CURSO, zelando pela sua
            qualidade e pelo cumprimento das metodologias de educação.
          </li>
        </ol>

        <ul>
          <li>
            Após a confirmação da cessão disponibilizar acesso à “Área de
            Alunos”.
          </li>
        </ul>

        <h3><strong>Cláusula Sétima – Da Rescisão</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          Em caso de inadimplemento de qualquer cláusula ou obrigação do
          presente contrato pelo ALUNO, poderá a FORNECEDORA resolvê-lo, ficando
          o aluno impedido de acessar a “Área de Alunos” e grupos do curso de
          forma imediata, sem que esta medida importe em qualquer tipo de
          indenização ao ALUNO.
        </p>

        <p>
          § 1º. Sem prejuízo das demais disposições previstas neste contrato, a
          FORNECEDORA poderá resolver imediatamente o presente instrumento caso
          o ALUNO: (i) use indevidamente os materiais disponibilizados para
          realização do curso, conforme indicado na cláusula terceira e
          subcláusulas; (ii) caso o aluno compartilhe <em>login</em> e senha.
        </p>

        <p>
          § 2º.
          <strong
            >Caso não haja o número mínimo de cessões previstas para início do
            CURSO a FORNECEDORA poderá cancelá-lo, devolvendo os valores ao
            ALUNO.
          </strong>
        </p>

        <p>
          § 3º. Em consonância com o artigo 49 do Código de Defesa do
          Consumidor, quando a contratação for efetivada fora do estabelecimento
          comercial da FORNECEDORA, poderá o ALUNO, no prazo de 7 (sete) dias
          corridos, contados da cessão, exercer o direito de arrependimento
          sobre o presente contrato, observado que:
        </p>

        <ol>
          <li>
            <strong
              >A garantia para a desistência imotivada prevista neste
              instrumento não é complementar à legal.</strong
            >
          </li>
        </ol>

        <ol>
          <li>
            Para cancelamento do CURSO no prazo de arrependimento o ALUNO deverá
            proceder através do meio de contato oficial disponível no WEBSITE da
            FORNECEDORA.
          </li>
        </ol>

        <ol>
          <li>
            A devolução do(s) valor(es) pago(s) em caso de exercício do direito
            de arrependimento no prazo contratual ocorrerá em no máximo 30
            (trinta) dias úteis, sendo computado(s) como marco inicial a data do
            fornecimento pela FORNECEDORA da ciência de que foi solicitado o
            cancelamento no prazo de arrependimento.
          </li>
        </ol>

        <h3><strong>Cláusula Oitava – Das Disposições Gerais </strong></h3>

        <p>Constituem disposições gerais aplicáveis ao presente contrato:</p>

        <ol>
          <li>
            As disposições do presente contrato somente poderão ser alteradas
            por instrumento que terá efeito de termo aditivo.
          </li>
        </ol>

        <ol>
          <li>
            O ALUNO é responsável civil e criminalmente pela veracidade dos
            dados inseridos no requerimento de cessão, declarações, informações
            e documentos que fornecer e pelas consequências que deles advierem.
          </li>
        </ol>

        <ol>
          <li>
            O ALUNO será responsável pelo ressarcimento de danos materiais,
            lucro cessante, perda de oportunidade e danos morais que culposa ou
            dolosamente causar para a FORNECEDORA e/ou terceiros.
          </li>
        </ol>

        <ol>
          <li>
            Serviços realizados por terceiros não cobertos pelo presente
            contrato deverão ser contratados e pagos diretamente pelo ALUNO.
          </li>
        </ol>

        <ul>
          <li>
            A tolerância das partes não implica em renúncia, perdão, novação ou
            alteração do pactuado neste instrumento, podendo a parte que se
            sentir lesada exigir o seu direito a qualquer tempo.
          </li>
        </ul>

        <ul>
          <li>
            Se qualquer das cláusulas deste contrato for considerada nula ou
            anulável judicialmente, tal cláusula será desconsiderada do
            contrato, permanecendo válida todas as demais, e em pleno vigor e
            efeito.
          </li>
        </ul>

        <ul>
          <li>
            A FORNECEDORA não se responsabiliza por eventuais problemas
            decorrentes da interrupção dos serviços de provedor de acesso à
            <em>internet</em> contratado pelo ALUNO, nem pela interrupção dos
            serviços em caso de falta de fornecimento de energia elétrica para o
            sistema do seu provedor de acesso, falhas no sistema de transmissão
            ou roteamento de acesso à <em>internet</em>, de incompatibilidade de
            sistemas do usuáriocom o provedor de acesso, de qualquer ação de
            terceiros que impeçam a prestação dos serviços, por problemas
            decorrentes de caso fortuito ou de força maior, e ainda, por
            determinação proveniente de decisão administrativa ou judicial.
          </li>
        </ul>

        <h3><strong>Cláusula Nona – Caso Fortuito ou Força Maior</strong></h3>

        <p>
          Conforme previsto no artigo 393 do Código Civil Brasileiro, nenhuma
          parte será responsabilizada por falhas no cumprimento de suas
          respectivas obrigações, quando o cumprimento de tais obrigações tenha
          sido impedido ou atrasado em virtude da ocorrência de eventos
          comprovadamente caracterizados como caso fortuito ou força maior.
        </p>

        <p>
          §1º Em atenção ao período de calamidade sanitária instaurado, conforme
          hoje já dispõem a Portaria n. 188/GM/MS, de 04 de fevereiro de 2020, a
          Portaria n. 356/GM/MS, de 11 de março de 2020, a Lei Federal n.
          13.979, de 6 de fevereiro de 2020, a Lei Federal&nbsp; n. 13.675, de
          11 de junho de 2018, Decreto n. 10.212, de 30 de janeiro de 2020, a
          Mensagem Presidencial n. 93/2020, bem como decretos regulamentadores e
          medidas provisórias correlatas, não serão considerados casos fortuitos
          ou força maior aqueles decorrentes de novos desdobramentos da pandemia
          de Coronavírus (2019-nCoV).
        </p>

        <p>
          §2º Em nenhuma hipótese será considerado como evento de força maior ou
          de caso fortuito a ocorrência de:
        </p>

        <ol>
          <li>
            Qualquer ação de qualquer autoridade pública que uma parte pudesse
            ter evitado se tivesse cumprido suas obrigações legais ou
            contratuais;
          </li>
        </ol>

        <ul>
          <li>
            Dificuldades econômicas ou financeiras de qualquer das partes.
          </li>
        </ul>

        <h3><strong>Cláusula Dez – Da Adesão</strong></h3>

        <p>
          Dando prosseguindo ao CURSO sem oposição ao presente contrato, que foi
          apresentado preliminarmente ao ALUNO, o ALUNO expressa ter lido e
          compreendido o presente contrato e os demais instrumentos jurídicos
          mencionados e disponibilizados no WEBSITE, tendo utilizado os
          mecanismos de atendimento para sanar e esclarecer todas suas dúvidas,
          e enfim estando de devidamente esclarecido e de acordo, na íntegra, em
          relação a todos os termos e condições que lhe foram apresentados,
          assume os direitos e obrigações estabelecidos, o que se dará por bem
          feito para todos os fins de direito.
        </p>

        <h2>Anexo II – Contrato de Prestação de Serviços (Mentoria)</h2>

        <p>São partes deste instrumento:</p>

        <p>
          De um lado, a FORNECEDORA identificada em epígrafe, detentora dos
          WEBSITES e subendereços, e de outro lado, o Contratante e/ou
          Responsável Financeiro, aderente aos presentes termos e condições
          gerais de prestação de serviços educacionais, devidamente identificado
          através das informações prestadas à plataforma de pagamentos
          (“CONTRATANTE”).
        </p>

        <h3><strong>Cláusula Primeira – Do Objeto</strong></h3>

        <p>
          Este contrato tem como objeto a prestação de serviços de “MENTORIA”.
        </p>

        <p>§ 1º. A mentoria tem as seguintes características:</p>

        <ol>
          <li>
            Vaga individual e personalíssima, razão pela qual é intransferível,
            por parte do CONTRATANTE para terceiros a qualquer título, gratuito
            ou oneroso;
          </li>
        </ol>

        <ol>
          <li>Número de vagas limitado;</li>
        </ol>

        <ol>
          <li>
            Prestação por meio eletrônico, através de encontros com frequência,
            horários, plataforma de acesso, por tempo de duraçãoeprazo
            especificados em instrumento próprio e antecipadamente;
          </li>
        </ol>

        <ol>
          <li>
            Disponibilização de acesso personalíssimo a plataforma eletrônica,
            denominada “Área de Membros”, onde serão disponibilizados conteúdos
            relativos à mentoria.
          </li>
        </ol>

        <h3><strong>Cláusula Segunda – Declarações do Contratante</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>O CONTRATANTE reconhece neste ato que:</p>

        <ol>
          <li>
            Preencheu formulário específico oferecido pela FORNECEDORA
            requerendo a prestação de seu serviço de mentoria.
          </li>
        </ol>

        <ol>
          <li>
            Passou por entrevista de aprovação feita realizada por profissional
            da FORNECEDORA, e declara que nessa oportunidade foi amplamente
            informado sobre como funcionará a mentoria.
          </li>
        </ol>

        <ol>
          <li>
            Foi devidamente informado de que sua vaga é pessoal, e
            intransferível, e que a turma é composta por vagas limitadas, razão
            pela qual esse compromisso é irrevogável e irretratável.
          </li>
        </ol>

        <ol>
          <li>
            Foi devidamente informado de que, apesar de tratar-se de vaga
            individual, o conteúdo será disponibilizado conjuntamente para um
            grupo – limitado – de pessoas.
          </li>
        </ol>

        <ul>
          <li>
            Está ciente de que o grupo tem vagas limitadas e por este motivo,
            não haverá devolução de valores em caso de desistência, vez que ao
            decidir ingressar na mentoria está ciente de que ocupou vaga que no
            processo seletivo poderia ser destinada a outra pessoa.
          </li>
        </ul>

        <ul>
          <li>
            Conversou previamente também em entrevista com profissional do
            departamento financeiro da FORNECEDORA e declara que foi amplamente
            informado sobre preço do serviço e condições de pagamento.
          </li>
        </ul>

        <ul>
          <li>
            Declara que tem condições financeiras de arcar integralmente com o
            valor remuneratório previsto neste contrato.
          </li>
        </ul>

        <p>
          § 1º.<strong
            >O CONTRATANTE declara-se ciente de que tem prazo de arrependimento
            improrrogável de7 (sete) dias contados da data do pagamento, caso
            tenha realizado a contratação fora do estabelecimento comercial da
            contratada, especialmente por telefone ou pela
            <em>internet</em></strong
          >.
        </p>

        <p>
          § 2º.<strong
            >A garantia para a desistência imotivada prevista neste instrumento
            não é complementar à legal.</strong
          >
        </p>

        <p>
          § 3º. A efetivação da matrícula, independente de ter transcorrido ou
          não o prazo de arrependimento supra, significa, para todos os fins,
          plena concordância com os termos e condições deste contrato.
        </p>

        <h3><strong>Cláusula Terceira – Do Acesso à Mentoria</strong></h3>

        <p>
          Após a efetivação da matrícula nos moldes da cláusula segunda supra, a
          FORNECEDORA disponibilizará o calendário dos encontros e o acesso à
          “Área de Membros”, cujo ingresso poderá se dar exclusivamente por meio
          de <em>login</em> e senha de natureza pessoal e intransferível.
        </p>

        <p>
          § 1º. A senha será de uso pessoal e intransferível do CONTRATANTE que
          responderá pela utilização indevida da mesma e por todos os danos e
          prejuízos decorrentes da disponibilização a terceiros, seja com o
          intuito de auferir lucro ou não, sendo a guarda da senha exclusiva
          responsabilidade do CONTRATANTE.
        </p>

        <p>
          § 2º. A senha garantirá acesso exclusivamente ao conteúdo da mentoria
          e o acesso à mentoria ocorre por prazo específico, findo o qual, será
          bloqueado automaticamente.&nbsp;
        </p>

        <p>
          § 3º. Não será permitido o acesso à mentoria pelo CONTRATANTE
          acompanhado por terceiro estranho ao objeto deste instrumento.
        </p>

        <p>
          § 4º. Não é permitido o acesso à “Área de Membros”de forma simultânea
          em mais de um terminal (computador<em>, tablet,</em> celular, ou
          qualquer outra tecnologia).
        </p>

        <p>
          § 5º. É terminantemente vedado ao CONTRATANTE, sob pena de imediato
          cancelamento da matrícula, com expulsão da mentoria sem direito à
          devolução de valores e sem prejuízo das demais medidas cabíveis no
          âmbito civil e criminal:
        </p>

        <ol>
          <li>Compartilhar seu <em>login</em> e senha com terceiros.</li>
        </ol>

        <ul>
          <li>
            Adquirir a mentoria em regime de “groupbuy” (compra coletiva) ou
            outra forma de rateio onde várias pessoas adquirem o acesso através
            do nome de uma única pessoa compartilhando-o entre si.
          </li>
        </ul>

        <ul>
          <li>
            Fazer uso de ferramentas para gravar a tela ou para de qualquer
            forma fazer o download dos vídeos da mentoria ainda que para uso
            exclusivo pessoal.
          </li>
        </ul>

        <ul>
          <li>
            Fazer uso de ferramentas para gravar o áudio dos vídeos ou para de
            qualquer forma fazer o download dos áudios não disponibilizados
            especificamente para esta finalidade, ainda que para uso exclusivo
            pessoal.
          </li>
        </ul>

        <ul>
          <li>
            Fazer uso de ferramentas para fotografar a imagem da tela, inclusive
            <em>prints </em>de tela, ainda que para uso exclusivo pessoal.
          </li>
        </ul>

        <ul>
          <li>
            Compartilhar a mentoria, de maneira gratuita ou onerosa, no “Mercado
            Livre”, “Mercado Pago”, “OLX”, “<em>Whatsapp</em>” ou qualquer tipo
            de plataforma, ou ainda em qualquer tipo de mídia, vez que este ato
            constitui a prática de pirataria e será punido na forma da lei.
          </li>
        </ul>

        <p>
          § 6º.Os encontros serão entregues de forma sequencial, nas datas
          previamente estipuladas, ao vivo e integralmente <em>online</em>.
        </p>

        <p>
          § 7º. O CONTRATANTE declara-se ciente de que a utilização das
          ferramentas pedagógicas disponibilizadas pela FORNECEDORA ao
          CONTRATANTE, tais como: a) materiais de apoio, b) aulas bônus; c)
          atividades e exercícios fazem parte do ambiente de mentoria e estão
          sujeitas aos termos de uso, política de privacidade e política de
          direitos autorais.
        </p>

        <p>
          § 8º. O CONTRATANTE declara-se ciente e concorda com o fato de que em
          hipótese alguma lhe está sendo comercializado qualquer direito de
          propriedade relativo ao conteúdo intelectual da mentoria, que está
          protegido por direitos autorais.
        </p>

        <p>
          § 9º. O CONTRATANTE é responsável pelo sigilo de seu acesso na “Área
          de Membros” sendo vedada a transferência a terceiros, sob pena de
          rescisão contratual e apuração de eventual violação de direitos
          autorais. Além disso, o CONTRATANTE compromete-se a manter seus dados
          cadastrais atualizados junto à FORNECEDORA, especialmente pelo acesso
          próprio na “Área de Membros”.
        </p>

        <p>
          § 10. Na participação em ferramentas pedagógicas de grupo (como
          fóruns, debates, chats etc.) ou mesmo em redes sociais, deverá o
          CONTRATANTE manter conduta social adequada à normalidade, eximindo-se
          de causar intranquilidade nos demais, expressar-se de modo obsceno,
          violar direitos, entre outras condutas que possam ser consideradas
          antissociais, hipótese em que poderá sofrer exclusão da ferramenta
          específica ou mesmo a rescisão do contrato em caso de conduta grave.
        </p>

        <p>
          § 11. Todo conteúdo disponibilizado para a mentoria, seja por escrito,
          audiovisual ou por quaisquer outras mídias, é de propriedade exclusiva
          da FORNECEDORA e protegido pela legislação nacional e internacional de
          propriedade intelectual, ficando vedada a reprodução, transmissão,
          divulgação, armazenamento e exibição no todo ou em parte, gratuita ou
          onerosa, a terceiros que não figurem como parte neste
          instrumento.&nbsp;
        </p>

        <p>
          § 12. O CONTRATANTE declara-se ciente e concorda que o acesso a
          mentoria não guarda relação com o número de parcelas a pagar para
          financiar a mentoria, podendo este serviço ser integralmente prestado
          em prazo inferior ou superior ao prazo de financiamento.&nbsp; A
          conclusão da mentoria não será considerada motivo para interromper o
          pagamento de quaisquer parcelas do financiamento escolhido pelo
          CONTRATANTE; da mesma forma o fato de o CONTRATANTE, por sua exclusiva
          responsabilidade, ter deixado de participar ou receber os serviços de
          mentoria no todo ou em parte ao longo do período em que para ele se
          encontra disponibilizado, não é motivo para ressarcimento de valores.
        </p>

        <h3><strong>Cláusula Quarta – Do Preço</strong></h3>

        <p>
          Pela prestação dos serviços objeto deste instrumento o CONTRATANTE
          pagará à <strong>FORNECEDORA</strong> o valor especificado em
          instrumento próprio no ato de requerimento.
        </p>

        <p>
          § 1º. Fica avençado que o atraso no pagamento de quaisquer parcelas
          será acrescido de multa moratória no importe de 2% (dois por cento)
          sobre o principal, acrescida de correção monetária pelo IGMP-FGV e
          juros de mora no importe de 1% (um por cento) ao mês, calculado
          <em>pro rata dies</em>, nos termos do disposto no art. 52, § 1º, do
          Código de Defesa do Consumidor.
        </p>

        <p>
          § 2º. Desde logo fica estabelecido que o atraso no pagamento de
          qualquer parcela ou obrigação de pagamento decorrente deste contrato
          permitirá a FORNECEDORA, cumulativamente:
        </p>

        <ol>
          <li>
            Registra a existência do débito nos institutos de proteção ao
            crédito, tais como SPC e SERASA, dentre outros, nos termos do
            parágrafo 2º art. 43 do Código de Defesa do Consumidor, sempre que o
            atraso de qualquer parcela for superior a 30 (trinta) dias.
          </li>
        </ol>

        <ol>
          <li>
            Protestar e/ou cobrar judicial ou extrajudicialmente o valor devido,
            devendo o CONTRATANTE arcar com todas custas, despesas e encargos de
            cobrança de 10% (dez por cento), além de honorários advocatícios de
            20% (vinte por cento), em ambos os casos calculados sobre o valor
            total do débito, acrescido de custas e despesas extrajudiciais e
            judiciais.
          </li>
        </ol>

        <ol>
          <li>
            Impedir a frequência do CONTRATANTE à mentoria bloqueando seu acesso
            à “Área de Membros”.
          </li>
        </ol>

        <ol>
          <li>
            Impedir o CONTRATANTE de usufruir de quaisquer serviços
            complementares oferecidos, incluindo, mas não se limitando ao acesso
            locais de solução de dúvidas, downloads etc.
          </li>
        </ol>

        <ul>
          <li>Rescindir este contrato.</li>
        </ul>

        <p>
          § 3º. Comprovado pela FORNECEDORA o pagamento da(s) parcela(s)
          inadimplida(s), deverá a FORNECEDORA requerer a exclusão do
          apontamento nos cadastros dos institutos de proteção ao crédito no
          prazo máximo de 05 (cinco) dias úteis, ficando aqui esclarecido que
          após o requerimento, haverá o prazo dos próprios órgãos para efetivar
          a retirada do nome.
        </p>

        <p>
          § 4º. Qualquer desconto eventualmente concedido ao CONTRATANTE terá
          caráter excepcional e não constituirá compromisso de concessão para
          outros serviços ou produtos que o CONTRATANTE pretenda contratar junto
          à FORNECEDORA.
        </p>

        <h3>
          <strong>Cláusula Quinta – Das Obrigações do Contratante</strong>
        </h3>

        <p>Constituem obrigações o CONTRATANTE:</p>

        <ol>
          <li>
            Em todas os encontros e ambientes da mentoria, respeitar as normas
            de convívio social e a de conduta, tanto em relação aos demais
            membros, quanto aos funcionários da FORNECEDORA e à própria
            FORNECEDORA.
          </li>
        </ol>

        <ol>
          <li>
            Para acesso ao conteúdo disponibilizado na “Área de Membros”,
            possuir equipamentos e softwares ligados à <em>internet</em>.
          </li>
        </ol>

        <ol>
          <li>
            Manter seus dados cadastrais atualizados e com informações
            verídicas, bem como zelar pela confidencialidade de seu login e
            senha, de forma a não permitir nem possibilitar o compartilhamento
            com terceiros.
          </li>
        </ol>

        <ol>
          <li>
            Não reproduzir, sob qualquer forma, o material da mentoria, sob pena
            de responder civil e criminalmente perante a FORNECEDORA e
            terceiros, nos termos da Lei 9.609 de 19 de fevereiro de 1998, por
            violação de propriedade intelectual, devendo o uso deste ser feito
            exclusivamente em âmbito privado pelo CONTRATANTE.
          </li>
        </ol>

        <ul>
          <li>
            Não comercializar ou ceder a qualquer título, no todo ou em parte,
            sua senha a terceiros estranhos à esta relação comercial.
          </li>
        </ul>

        <ul>
          <li>
            Não copiar, por qualquer meio, inclusive <em>download</em> ou
            gravação de tela, os vídeos, áudios e documentos, devendo acessar a
            mentoria apenas de forma online e exclusivamente através da
            plataforma de ensino.
          </li>
        </ul>

        <ul>
          <li>
            Seguir os padrões de conduta estabelecidos e vigentes na
            <em>internet</em>, abstendo-se de: (i) violar a privacidade dos
            outros usuários; (ii) permitir que outras pessoas utilizem seu
            acesso a mentoria; (iii) utilizar qualquer técnica de invasão ao
            site que viole a segurança do ambiente de treinamento e de sites a
            ele relacionados; (iv) não agir conscientemente para destruir
            arquivos ou programas do ambiente e de sites relacionados; (v)
            utilizar os nomes e e-mails dos participantes da mentoria para fins
            comerciais; (vi) enviar mensagens que possam ser consideradas
            obscenas e/ou fora dos padrões éticos e de bons costumes.
          </li>
        </ul>

        <h3><strong>Cláusula Sexta – Das Obrigações da Fornecedora</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>Compete exclusivamente à FORNECEDORA:</p>

        <ol>
          <li>
            Fornecer o serviço de mentoria desde que o CONTRATANTE esteja em dia
            com suas obrigações e não tenha cometido falta grave que justifique
            sua expulsão.
          </li>
        </ol>

        <ol>
          <li>
            Manter corpo administrativo adequado para solucionar dúvidas
            referentes à acesso ou a mentoria em horários previamente
            combinados.
          </li>
        </ol>

        <ol>
          <li>
            Realizar o planejamento da mentoria, incluindo, mas não se
            limitando, a fixação da carga horária total da mentoria, designação
            dos instrutores para eventuais aulas bônus, se aplicáveis, definição
            de datas de eventos se forem aplicáveis, distribuição dos encontros
            e fixação do calendário dos mesmos, além de outras providências que
            a atividade exigir.
          </li>
        </ol>

        <ol>
          <li>
            Coordenar administrativamente a mentoria, zelando pela sua qualidade
            e pelo cumprimento de seu programa.
          </li>
        </ol>

        <ul>
          <li>
            Após a confirmação da matrícula disponibilizar acesso à “Área de
            Membros”e o calendário dos encontros.
          </li>
        </ul>

        <h3><strong>Cláusula Sétima – Da Rescisão</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          Em caso de inadimplemento de qualquer cláusula ou obrigação do
          presente contrato pelo CONTRATANTE, poderá a FORNECEDORA rescindi-lo,
          ficando o CONTRATANTE impedido de acessar a “Área de Membros”e grupos
          da mentoria de forma imediata, sem que esta medida importe em qualquer
          tipo de indenização por parte do CONTRATANTE.
        </p>

        <p>
          § 1º. Sem prejuízo das demais disposições previstas neste contrato, a
          FORNECEDORA poderá rescindir imediatamente o presente instrumento caso
          o CONTRATANTE: (i) use indevidamente os materiais disponibilizados
          para realização da mentoria, conforme indicado na cláusula terceira e
          subcláusulas; (ii) caso o CONTRATANTE compartilhe <em>login</em> e
          senha.
        </p>

        <p>
          § 2º.<strong
            >Caso não haja o número mínimo de matrículas previstas para início
            da mentoria a FORNECEDORA poderá cancelá-lo devolvendo os valores ao </strong
          ><strong>CONTRATANTE</strong><strong>. </strong>
        </p>

        <p>
          § 3º.<strong
            >Em consonância com os termos do artigo 49 do Código de Defesa do
            Consumidor, quando a contratação for efetivada fora do
            estabelecimento comercial, no caso de compras por via digital,
            poderá o </strong
          ><strong>CONTRATANTE</strong
          ><strong
            >, no prazo de 7 (sete) dias corridos, contados da matrícula,
            exercer o direito de arrependimento. Fora deste período, não haverá
            devolução como forma de indenizar a FORNECEDORA por perda de
            oportunidade, pois o </strong
          ><strong>CONTRATANTE </strong
          ><strong
            >compreende que as turmas têm vagas limitadas e que quando afirma
            que fará a mentoria retira a possibilidade de outro </strong
          ><strong>CONTRATANTE </strong
          ><strong
            >fazer a mentoria em seu lugar, o que acarreta prejuízo para a
            FORNECEDORA, observado que:</strong
          ><strong></strong>
        </p>

        <ol>
          <li>
            <strong
              >A garantia para a desistência imotivada prevista neste
              instrumento não é complementar à legal.</strong
            >
          </li>
        </ol>

        <ol>
          <li>
            Para cancelamento da mentoria no prazo de arrependimento o
            CONTRATANTE deverá proceder através do meio de contato oficial
            disponível no WEBSITE da FORNECEDORA.
          </li>
        </ol>

        <ol>
          <li>
            A devolução do(s) valor(es) pago(s) em caso de exercício do direito
            de arrependimento no prazo contratual ocorrerá em no máximo 30
            (trinta) dias úteis, sendo computado(s) como marco inicial a data do
            fornecimento pela FORNECEDORA da ciência de que foi solicitado o
            cancelamento no prazo de arrependimento.
          </li>
        </ol>

        <h3><strong>Cláusula Oitava – Das disposições gerais </strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>Constituem disposições gerais do presente instrumento:</p>

        <ol>
          <li>
            As disposições do presente contrato somente poderão ser alteradas
            por escrito, mediante assinatura do competente termo aditivo (por
            meio físico ou digital).
          </li>
        </ol>

        <ol>
          <li>
            O CONTRATANTE declara ter lido previamente o contrato, os termos de
            uso do site, a política de privacidade e os termos de propriedade
            intelectual.
          </li>
        </ol>

        <ol>
          <li>
            O CONTRATANTE é responsável civil e criminalmente pela veracidade
            dos dados inseridos no Requerimento de Matrícula, declarações,
            informações e documentos que fornecer e pelas consequências que
            deles advierem.
          </li>
        </ol>

        <ol>
          <li>
            O CONTRATANTE será responsável pelo ressarcimento de danos
            materiais, lucro cessante, perda de oportunidade e danos morais que
            culposa ou dolosamente causar para a FORNECEDORA e/ou terceiros.
          </li>
        </ol>

        <ul>
          <li>
            Serviços realizados por terceiros não cobertos pelo presente
            contrato deverão ser contratados e pagos diretamente pelo
            CONTRATANTE.
          </li>
        </ul>

        <ul>
          <li>
            A tolerância das partes não implica em renúncia, perdão, novação ou
            alteração do pactuado neste instrumento, podendo a parte que se
            sentir lesada exigir o seu direito a qualquer tempo.
          </li>
        </ul>

        <ul>
          <li>
            Se qualquer das cláusulas deste contrato for considerada nula ou
            anulável judicialmente, tal cláusula será desconsiderada do
            contrato, permanecendo válida todas as demais, e em pleno vigor e
            efeito.
          </li>
        </ul>

        <ul>
          <li>
            A FORNECEDORA não se responsabiliza por eventuais problemas
            decorrentes da interrupção dos serviços de provedor de acesso à
            <em>internet</em> contratado pelo CONTRATANTE, nem pela interrupção
            dos serviços em caso de falta de fornecimento de energia elétrica
            para o sistema do seu provedor de acesso, falhas no sistema de
            transmissão ou roteamento de acesso à internet, de incompatibilidade
            de sistemas do usuário com o provedor de acesso, de qualquer ação de
            terceiros que impeçam a prestação dos serviços, por problemas
            decorrentes de caso fortuito ou de forma maior, e ainda, por
            determinação proveniente de decisão administrativa ou judicial.
          </li>
        </ul>

        <h3><strong>Cláusula Nona – Caso Fortuito ou Força Maior</strong></h3>

        <p>
          Conforme previsto no artigo 393 do Código Civil Brasileiro, nenhuma
          parte será responsabilizada por falhas no cumprimento de suas
          respectivas obrigações, quando o cumprimento de tais obrigações tenha
          sido impedido ou atrasado em virtude da ocorrência de eventos
          comprovadamente caracterizados como caso fortuito ou força maior.
        </p>

        <p>
          §1º Em atenção ao período de calamidade sanitária instaurado, conforme
          hoje já dispõem a Portaria n. 188/GM/MS, de 04 de fevereiro de 2020, a
          Portaria n. 356/GM/MS, de 11 de março de 2020, a Lei Federal n.
          13.979, de 6 de fevereiro de 2020, a Lei Federal&nbsp; n. 13.675, de
          11 de junho de 2018, Decreto n. 10.212, de 30 de janeiro de 2020, a
          Mensagem Presidencial n. 93/2020, bem como decretos regulamentadores e
          medidas provisórias correlatas, não serão considerados casos fortuitos
          ou força maior aqueles decorrentes de novos desdobramentos da pandemia
          de Coronavírus (2019-nCoV).
        </p>

        <p>
          §2º Em nenhuma hipótese será considerado como evento de força maior ou
          de caso fortuito a ocorrência de:
        </p>

        <ol>
          <li>
            Qualquer ação de qualquer autoridade pública que uma parte pudesse
            ter evitado se tivesse cumprido suas obrigações legais ou
            contratuais;
          </li>
        </ol>

        <ul>
          <li>
            Dificuldades econômicas ou financeiras de qualquer das partes.
          </li>
        </ul>

        <h3><strong>Cláusula Dez – Da Adesão</strong><strong></strong></h3>

        <p>
          Dando prosseguindo à mentoria sem oposição ao presente contrato, que
          foi apresentado preliminarmente ao CONTRATANTE, o CONTRATANTE expressa
          ter lido e compreendido o presente contrato e os demais instrumentos
          jurídicos mencionados e disponibilizados no WEBSITE, tendo utilizado
          os mecanismos de atendimento para sanar e esclarecer todas suas
          dúvidas, e enfim estando de devidamente esclarecido e de acordo, na
          íntegra, em relação a todos os termos e condições que lhe foram
          apresentados, assume os direitos e obrigações estabelecidos, o que se
          dará por bem feito para todos os fins de direito.
        </p>

        <h2>Anexo III – Contrato de Prestação de Serviços (Imersão)</h2>

        <p>São partes deste instrumento:</p>

        <p>
          De um lado, a FORNECEDORA identificada em epígrafe, detentora dos
          WEBSITES e subendereços, e de outro lado, o Contratante e/ou
          Responsável Financeiro, aderente aos presentes termos e condições
          gerais de prestação de serviços educacionais, devidamente identificado
          através das informações prestadas à plataforma de pagamentos
          (“CONTRATANTE”).
        </p>

        <h3><strong>Cláusula Primeira – Do Objeto</strong></h3>

        <p>
          1.1. Este contrato tem como objeto a prestação de serviços
          educacionais concernentes ao serviço de “IMERSÃO”.
        </p>

        <p>1.2. A imersão tem como características:</p>

        <ol>
          <li>
            Vaga individual e <em>intuito personae</em> razão pela qual é
            intransferível, por parte do CONTRATANTE para terceiros;
          </li>
        </ol>

        <ol>
          <li>Número de vagas limitado;</li>
        </ol>

        <ol>
          <li>
            Workshops, encontros e/ou palestras presenciais em locais, datas e
            horários a serem informados previamente pela FORNECEDORA;
          </li>
        </ol>

        <ol>
          <li>
            Passagens, hospedagem, alimentação e traslados por conta do
            CONTRATANTE.
          </li>
        </ol>

        <h3><strong>Cláusula Segunda – Da Matrícula</strong></h3>

        <p>2.1. O CONTRATANTE reconhece e declara neste ato que:</p>

        <ul>
          <li>
            Preencheu corretamente o formulário (Ficha de Inscrição)
            disponibilizado pela <strong>FORNECEDORA</strong>;
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Possui passaporte e visto, se aplicável, para os locais de
              realização da imersão, com validade igual ou superior a 6 (seis)
              meses a partir da data prevista de partida;</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            Passou por entrevista de aprovação feita realizada por profissional
            da FORNECEDORA, e declara que previamente e, novamente, nessa
            oportunidade foi amplamente informado sobre e que compreendeu
            completamente como funcionará a imersão e metodologia de serviço;
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Está ciente de que precisa estar no local designado para a
              realização da imersão com antecedência, responsabilizando
              integralmente por esta obrigação;</strong
            >
          </li>
        </ul>

        <ol>
          <li>
            <strong
              >Foi devidamente informado de que sua vaga é pessoal, e
              intransferível, e que a turma é composta por número limitado de
              membros, e que a contratação da imersão ensejará a realização de
              gastos, tais como mas não limitados a realização de reservas,
              vagas, gastos com comida e/ou bebidas, pela FORNECEDORA, razão
              pela qual este compromisso é irrevogável e irretratável;</strong
            >
          </li>
        </ol>

        <ul>
          <li>
            Foi devidamente informado de que, apesar de tratar-se de vaga
            individual, o conteúdo será disponibilizado conjuntamente para um
            grupo de pessoas;
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Está ciente de que a turma tem vaga limitadas e por este motivo,
              não haverá devolução de valores em caso de desistência fora das
              condições expressas neste instrumento, vez que ao decidir
              participar da imersão está ciente de que ocupou vaga que no
              processo seletivo poderia ser destinada a outra pessoa;</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            Conversou previamente também em entrevista com profissional do
            departamento financeiro da FORNECEDORA e declara que foi amplamente
            informado sobre preço do serviço e condições de pagamento;
          </li>
        </ul>

        <ul>
          <li>
            Declara que tem condições financeiras de arcar integralmente com o
            valor remuneratório previsto neste contrato;
          </li>
        </ul>

        <ul>
          <li>
            <strong
              >Está ciente de que, independentemente da data de assinatura deste
              contrato, a matrícula somente se efetivará com a quitação válida
              da primeira ou única parcela.</strong
            >
          </li>
        </ul>

        <h3><strong>Cláusula Terceira – Do Acesso à Imersão</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          3.1. Após a efetivação da matrícula nos moldes da cláusula segunda
          supra, a FORNECEDORA disponibilizará o calendário da imersão, e
          indicará, antes de cada encontro,<em>workshop</em> ou palestra, o
          local e horário de sua realização.
        </p>

        <p>
          3.2. Não é permitido o acesso à imersão pelo CONTRATANTE acompanhado
          por terceiro estranho ao objeto deste instrumento.
        </p>

        <p>
          3.3. É terminantemente vedado ao CONTRATANTE, sob pena de imediato
          cancelamento da matrícula, com expulsão da imersão sem direito à
          devolução de valores e sem prejuízo das demais medidas cabíveis no
          âmbito civil e criminal:
        </p>

        <ul>
          <li>
            Compartilhar material que receba na imersão com terceiros estranhos
            a este contrato;
          </li>
        </ul>

        <ul>
          <li>
            Fazer uso de ferramentas para gravar a imersão, ainda que para uso
            exclusivo pessoal;
          </li>
        </ul>

        <ol>
          <li>
            Fazer uso de ferramentas reproduzir materiais que receba na imersão,
            ainda que para uso exclusivo pessoal;
          </li>
        </ol>

        <ul>
          <li>
            Compartilhar conteúdo da imersão, de maneira gratuita ou onerosa, no
            “Mercado Livre”, “Mercado Pago”, “OLX”, “<em>Whatsapp</em>”,
            “<em>Facebook</em>”, ou qualquer tipo de plataforma, ou ainda em
            qualquer tipo de mídia, vez que este ato constitui a prática de
            pirataria e será punido na forma da lei;
          </li>
        </ul>

        <p>
          3.4. O CONTRATANTE declara-se ciente de que a utilização de
          ferramentas pedagógicas disponibilizadas pela
          <strong>FORNECEDORA</strong> ao CONTRATANTE tais como mas não
          limitadas a materiais de apoio, aulas bônus, gravações das aulas e
          atividades e exercícios, de forma <em>online</em> através de
          plataforma que indique, mediante <em>login</em> e senha – caso venha a
          fazê-lo – fazem parte do ambiente de aprendizado e estão sujeitas aos
          termos de uso, política de privacidade e política de direitos
          autorais.
        </p>

        <p>
          3.5. O CONTRATANTE declara-se ciente e concorda com o fato de que em
          hipótese alguma lhe está sendo comercializado qualquer direito de
          propriedade relativo à imersão, seu conteúdo ou materiais.
        </p>

        <p>
          3.6. Na participação de atividades pedagógicas de grupo (como fóruns,
          debates etc., virtuais ou não) ou mesmo em redes sociais vinculadas à
          imersão, deverá o CONTRATANTE manter conduta compatível com a vida
          acadêmica sob pena de sua atitude antissocial ser causa de exclusão da
          atividade específica ou mesmo a rescisão do contrato em caso de
          conduta grave.
        </p>

        <p>
          3.7. Todo conteúdo disponibilizado para a imersão, seja por escrito,
          audiovisual ou por quaisquer outras mídias, é de propriedade exclusiva
          da <strong>FORNECEDORA</strong> e de seus respectivos produtores, e
          protegido pela legislação nacional e internacional de propriedade
          intelectual, ficando vedada a reprodução, transmissão, divulgação,
          armazenamento e exibição no todo ou em parte, gratuita ou onerosa, a
          terceiros que não figurem como parte neste instrumento.&nbsp;
        </p>

        <p>
          3.8. O CONTRATANTE declara-se ciente e concorda que a duração da
          imersão não guarda relação com o número de parcelas a pagar para
          financiar a imersão, podendo este ser integralmente cursado em prazo
          inferior ou superior ao prazo de financiamento. A conclusão da imersão
          não será considerada motivo para interromper o pagamento de quaisquer
          parcelas do financiamento escolhido pelo CONTRATANTE, e da mesma forma
          o fato de o CONTRATANTE, por sua exclusiva responsabilidade, ter
          deixado de comparecer à imersão no todo ou em parte ao longo do
          período em que para ele se encontra disponibilizado, não é motivo para
          ressarcimento de valores.
        </p>

        <h3><strong>Cláusula Quarta – Do Preço</strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          4.1. Pela prestação dos serviços objeto deste instrumento o
          CONTRATANTE pagará à FORNECEDORA o valor especificado em instrumento
          próprio no ato de requerimento, em número de vezes e vencimentos –
          quando disponível essa opção – que tenha escolhido.
        </p>

        <p>
          4.2. Fica avençado que no atraso no pagamento de quaisquer parcelas, o
          valor da parcela em atraso será acrescido de multa moratória no
          importe de 2% (dois por cento) sobre o principal, mais correção
          monetária pelo IGPM/FGV e juros de mora no importe de 1% (um por
          cento) ao mês, calculado <em>pro rata dies</em>, nos termos do
          disposto no art. 52, § 1°, do Código de Defesa do Consumidor.
        </p>

        <p>
          4.3. Desde logo fica estabelecido que o atraso no pagamento de
          qualquer parcela ou obrigação de pagamento decorrente deste contrato
          permitirá a <strong>FORNECEDORA</strong>, cumulativamente:
        </p>

        <ol>
          <li>
            Registrar a existência do débito nos institutos de proteção ao
            crédito, tais como SPC e SERASA, dentre outros, nos termos do § 2º
            do art. 43 do Código de Defesa do Consumidor, sempre que o atraso de
            qualquer parcela for superior a 30 (trinta) dias;
          </li>
        </ol>

        <ul>
          <li>
            Protestar o título executivo extrajudicial e/ou cobrar judicial ou
            extrajudicialmente o valor devido, devendo o CONTRATANTE arcar com
            todas custas, despesas e encargos de cobrança, equivalentes a 10%
            (dez por cento), além de honorários advocatícios de 20% (vinte por
            cento), em ambos os casos calculados sobre o valor total do débito,
            acrescido de custas e despesas extrajudiciais e judiciais;
          </li>
        </ul>

        <ul>
          <li>
            Impedir a frequência do CONTRATANTE à imersão bloqueando seu acesso
            a local de encontro ou atividades, ou em qualquer outra dependência
            reservada para a prestação dos serviços da imersão;
          </li>
        </ul>

        <ul>
          <li>
            Impedir o CONTRATANTE de usufruir de quaisquer serviços
            complementares oferecidos, incluindo, mas não se limitando ao acesso
            aos locais de solução de dúvidas, <em>downloads</em> etc.;
          </li>
        </ul>

        <ul>
          <li>Rescindir este contrato.</li>
        </ul>

        <p>
          4.4. Comprovado à <strong>FORNECEDORA</strong> o pagamento da(s)
          parcela(s) inadimplida(s), deverá a
          <strong>FORNECEDORA</strong> requerer a exclusão do apontamento nos
          cadastros dos institutos de proteção ao crédito, ou a extinção da ação
          se ajuizada, no prazo máximo de 05 (cinco) dias úteis, ficando aqui
          esclarecido que após o requerimento, haverá o prazo dos próprios
          órgãos para efetivar a baixa requerida.
        </p>

        <p>
          4.5. Qualquer desconto eventualmente concedido ao CONTRATANTE terá
          caráter excepcional e não constituirá compromisso de concessão para
          outros serviços ou produtos que o CONTRATANTE pretenda contratar junto
          à FORNECEDORA.
        </p>

        <p>
          4.6. Os descontos eventualmente concedidos nas parcelas mensais
          incidirão apenas e tão somente sobre as parcelas vincendas (a vencer),
          não possuindo caráter retroativo (sobre as parcelas já vencidas), e
          ainda estarão sempre condicionados à manutenção da condição que lhes
          houver dado origem.
        </p>

        <p>
          4.7. Não estão incluídos neste contrato os valores referentes a
          produtos/serviços para além dos encontros e/ou atividades conforme
          cronograma da imersão, tais como, mas não limitados, adaptações,
          uniformes, transporte, alimentação, material pessoal de uso individual
          do CONTRATANTE, excursões, visitas e estudos de meio.
        </p>

        <h3>
          <strong>Cláusula Quinta – Das Obrigações do Contratante</strong>
        </h3>

        <p>5.1. Constituem obrigações do CONTRATANTE:</p>

        <ol>
          <li>
            <strong
              >Arcar com as despesas e responsabilidades referentes a passagens,
              viagens, hospedagens, alimentação, transporte e traslados, bem
              como pela regularidade de seu passaporte;</strong
            >
          </li>
        </ol>

        <ul>
          <li>
            <strong
              >Estar no local designado para&nbsp; a Imersão, em tempo hábil
              para não haver atrasos e nem saídas antecipadas;</strong
            >
          </li>
        </ul>

        <ul>
          <li>
            Em todos os encontros, atividades e ambientes da imersão, respeitar
            as normas de convívio social e a de conduta, tanto em relação aos
            demais membros, quanto aos funcionários da
            <strong>FORNECEDORA</strong> e à própria
            <strong>FORNECEDORA</strong>;
          </li>
        </ul>

        <ul>
          <li>
            Para acesso ao conteúdo disponibilizado em plataforma virtual
            vinculada à imersão – caso venha a existir, como liberalidade da
            fornecedora -, possuir equipamentos e <em>softwares</em> ligados à
            <em>internet</em>;
          </li>
        </ul>

        <ul>
          <li>
            Manter seus dados cadastrais atualizados e com informações
            verídicas, bem como zelar pela confidencialidade de <em>login</em> e
            senha que porventura receber para acesso a plataforma virtual de
            apoio vinculada à imersão – se existente –, de forma a não permitir
            nem possibilitar o compartilhamento com terceiros;
          </li>
        </ul>

        <ul>
          <li>
            Não reproduzir, sob qualquer forma, o material da imersão, sob pena
            de responder civil e criminalmente perante a
            <strong>FORNECEDORA</strong> e terceiros, nos termos da Lei
            9.609/1998, por violação de propriedade intelectual, devendo o uso
            deste ser feito exclusivamente em âmbito privado pelo CONTRATANTE;
          </li>
        </ul>

        <ul>
          <li>
            Não comercializar ou ceder a qualquer título, no todo ou em parte,
            acesso à imersão a terceiros estranhos a esta relação comercial;
          </li>
        </ul>

        <ul>
          <li>
            Não gravar ou reproduzir por qualquer meio, os encontros e/ou
            atividades ou materiais disponibilizados da imersão.
          </li>
        </ul>

        <p>
          5.2. Em caso de dano material ao patrimônio da
          <strong>FORNECEDORA</strong>, independentemente de culpa ou dolo, o
          CONTRATANTE assume o compromisso de indenizar os danos causados por
          eventuais prejuízos que vier(em) a causar à
          <strong>FORNECEDORA</strong> e/ou a terceiros, assumindo integral
          responsabilidade pelos atos praticados em decorrência da inobservância
          das normas de segurança, recomendações, orientações, instruções e
          alertas de docentes, instrutores, pessoal técnico e administrativo, e
          prepostos.
        </p>

        <p>
          5.3. Com a efetivação da matrícula, o CONTRATANTE autoriza a
          <strong>FORNECEDORA</strong> a utilizar, sem ônus, as suas informações
          cadastrais, a imagem e a voz para fins de divulgação da imersão
          contratada e de suas atividades, inclusive concursos, premiações e
          outros afins, podendo veiculá-las pelos meios de comunicação
          disponíveis.
        </p>

        <h3><strong>Cláusula Sexta – Das Obrigações da Fornecedora</strong></h3>

        <p>6.1. Compete exclusivamente à <strong>FORNECEDORA</strong>:</p>

        <ol>
          <li>
            O planejamento pedagógico, a prestação dos serviços, a designação de
            docentes qualificados, a modalidade de ensino e a orientação
            didático-pedagógica, bem como outros procedimentos necessários,
            inclusive modificações, adaptações e ou exclusões que se fizerem
            necessárias, ao bom desenvolvimento dos serviços contratados, nos
            termos das normas legais e regimentais especificas em vigor.
          </li>
        </ol>

        <ul>
          <li>
            Fornecer e garantir o acesso à imersão desde que o CONTRATANTE
            esteja em dia com suas obrigações contratuais e não tenha cometido
            falta grave que justifique sua expulsão;
          </li>
        </ul>

        <ul>
          <li>
            Manter corpo administrativo adequado para solucionar dúvidas
            referentes à imersão em horários previamente combinados;
          </li>
        </ul>

        <ul>
          <li>
            Coordenar administrativamente a imersão, zelando pela sua qualidade
            e pelo cumprimento das metodologias de educação;
          </li>
        </ul>

        <p>
          6.2. A <strong>FORNECEDORA</strong> não assume qualquer
          responsabilidade em relação à pessoa do CONTRATANTE por quaisquer
          danos ocasionados por terceiros que ele(a) venha a sofrer fora ou nas
          adjacências da sua unidade educacional, e, ainda, em razão da
          inobservância de normas de segurança, recomendações, instruções e
          alertas de docentes, instrutores e pessoal técnico e administrativo.
        </p>

        <h3><strong>Cláusula Sétima – Da Rescisão</strong></h3>

        <p>
          7.1. Em caso de inadimplemento de qualquer cláusula ou obrigação do
          presente contrato pelo CONTRATANTE, poderá a
          <strong>FORNECEDORA</strong> rescindi-lo, ficando o CONTRATANTE
          imediatamente impedido de acessar a imersão, sem que esta medida
          importe em qualquer tipo de indenização ao CONTRATANTE.
        </p>

        <p>
          <strong
            >7.2. Poderá o CONTRATANTE exercer o direito de arrependimento,
            recebendo integralmente os valores já pagos e interrompendo-se a
            cobrança de parcelas vincendas, em 7 (</strong
          ><strong>sete</strong
          ><strong>) dias, a contar da efetivação da matrícula.</strong>
        </p>

        <p>
          <strong
            >7.3. Caso a matrícula tenha sido efetivada com menos de 7 (sete)
            dias de antecedência da data prevista para o início da imersão, o
            prazo para exercício do direito de arrependimento previsto no item
            7.3 retro será reduzido para as 24h (vinte e quatro horas) seguintes
            à efetivação da matrícula.</strong
          >
        </p>

        <p>
          <strong
            >7.4. O CONTRATANTE reconhece a razoabilidade e equilíbrio das
            disposições contidas nos itens 7.3. e 7.4 retro, de modo que, fora
            dos prazos previstos não haverá devolução dos valores pagos como
            forma de indenizar a FORNECEDORA pela perda da oportunidade, posto
            que as turmas têm vagas limitadas e a resilição contratual em data
            próxima à realização da imersão impede a possibilidade de outro
            membro fazer a matrícula em seu lugar.</strong
          >
        </p>

        <p>
          7.5. Caso não haja o número mínimo de matrículas previstas para início
          da imersão a <strong>FORNECEDORA</strong> poderá cancelá-lo,
          devolvendo os valores pagos pelo CONTRATANTE.
        </p>

        <p>
          7.6. Para desistência da imersão no prazo de arrependimento conforme
          as condições deste contrato, o CONTRATANTE deverá formalizar o pedido
          por escrito ou presencialmente na sede da
          <strong>FORNECEDORA</strong>. A devolução dos valores pagos ocorrerá
          no prazo máximo de 30 (trinta) dias úteis, sendo computado como marco
          inicial a data do fornecimento pela <strong>FORNECEDORA</strong> da
          ciência de que foi comunicada a desistência no prazo de
          arrependimento.
        </p>

        <p>
          7.7. A simples ausência aos encontros e às demais atividades da
          imersão exigidas não caracterizam desistência e tampouco exime o
          pagamento das mensalidades, tendo em vista a disponibilidade do
          serviço e a vaga garantida. Até que ocorra a devida formalização da
          desistência ou do cancelamento serão cobrados os valores dos encontros
          e/ou outras atividades realizadas.
        </p>

        <p>
          7.8. No ato da desistência fica rescindido este contrato, não sendo
          devido ao CONTRATANTE reembolso pelas quantias pagas,
          <strong
            >pois o CONTRATANTE compreende que as turmas têm vagas limitadas e
            que quando firmou compromisso de que ocuparia vaga na imersão, a
            reservou para si e impossibilitou a matrícula de outro membro em seu
            lugar.</strong
          >
        </p>

        <h3><strong>Cláusula Oitava – Das Disposições Gerais </strong></h3>

        <h3><strong>&nbsp;</strong></h3>

        <p>
          8.1. As disposições do presente contrato somente poderão ser alteradas
          por escrito, mediante assinatura do competente termo aditivo (por meio
          físico ou digital).
        </p>

        <p>
          8.2. O CONTRATANTE declara ter lido previamente o contrato, os termos
          de uso do site da <strong>FORNECEDORA</strong>, a política de
          privacidade e os termos de propriedade intelectual.
        </p>

        <p>
          8.3. O CONTRATANTE é responsável civil e criminalmente pela veracidade
          dos dados inseridos no formulário de requerimento de matrícula que
          realizar, declarações, informações e documentos que fornecer e pelas
          consequências que deles advierem.
        </p>

        <p>
          8.4. O CONTRATANTE será responsável pelo ressarcimento de danos
          materiais, lucros cessantes, perda de oportunidade e danos morais que
          culposa ou dolosamente causar para a <strong>FORNECEDORA</strong> e/ou
          terceiros.
        </p>

        <p>
          8.5. Serviços realizados por terceiros não cobertos pelo presente
          contrato deverão ser contratados e pagos diretamente pelo CONTRATANTE.
        </p>

        <p>
          8.6. A tolerância das partes não implica em renúncia, perdão, novação
          ou alteração do pactuado neste instrumento, podendo a parte que se
          sentir lesada exigir o seu direito a qualquer tempo.
        </p>

        <p>
          8.7. Se qualquer das cláusulas deste contrato for considerada nula ou
          anulável judicialmente, tal cláusula será desconsiderada do contrato,
          permanecendo válida todas as demais, e em pleno vigor e efeito.
        </p>

        <p>
          8.8. A <strong>FORNECEDORA</strong> não se responsabiliza por
          eventuais problemas decorrentes da interrupção de serviços de provedor
          de acesso à <em>internet</em> contratado pelo CONTRATANTE, nem pela
          interrupção dos serviços em caso de falta de fornecimento de energia
          elétrica para o sistema do seu provedor de acesso, falhas no sistema
          de transmissão ou roteamento de acesso à <em>internet</em>, de
          incompatibilidade de sistemas do CONTRATANTE com o provedor de acesso,
          de qualquer ação de terceiros que impeçam a o acesso do CONTRATANTE
          aos serviços eventualmente prestados e disponibilizados pela
          <strong>FORNECEDORA</strong>, por problemas decorrentes de caso
          fortuito ou de forma maior, e ainda, por determinação proveniente de
          decisão administrativa ou judicial.
        </p>

        <h3><strong>Cláusula Nona – Caso Fortuito ou Força Maior</strong></h3>

        <p>
          9.1. Conforme previsto no artigo 393 do Código Civil Brasileiro,
          nenhuma parte será responsabilizada por falhas no cumprimento de suas
          respectivas obrigações, quando o cumprimento de tais obrigações tenha
          sido impedido ou atrasado em virtude da ocorrência de eventos
          comprovadamente caracterizados como caso fortuito ou força maior.
        </p>

        <p>
          9.2. Os fatos decorrentes de novos desdobramentos da pandemia de
          Coronavírus (2019-nCoV), conforme hoje já dispõem a Portaria n.
          188/GM/MS, de 04 de fevereiro de 2020, a Portaria n. 356/GM/MS, de 11
          de março de 2020, a Lei Federal n. 13.979,de 6 de fevereiro de 2020,a
          Lei Federal&nbsp; n. 13.675, de 11 de junho de 2018, Decreto n.
          10.212, de 30 de janeiro de 2020, a Mensagem Presidencial n. 93/2020,
          bem como decretos regulamentadores e medidas provisórias correlatas,
          não serão considerados Caso Fortuito ou Força maior, exceto as
          determinações de Autoridades, inclusive Sanitárias, que proíbam a
          realização de eventos e/ou prestação de serviços da natureza do objeto
          deste contrato.
        </p>

        <p>
          9.3. Em decorrência da Medida Provisória n. 948, editada em 08 de
          abril de 2020, que dispõe sobre o cancelamento de serviços, de
          reservas e de eventos dos setores de turismo e cultura, em razão do
          estado de calamidade pública reconhecido pelo Decreto Legislativo nº
          6, de 20 de março de 2020, e da emergência de saúde pública de
          importância internacional decorrente do Coronavírus
          (<strong>covid-19</strong>), a FORNECEDORA não estará obrigada ao
          reembolso dos valores pagos pelo CONTRATANTE, desde que assegure a
          remarcação dos eventos que vierem a ser cancelados, a disponibilização
          de crédito para uso ou abatimento na compra de outros infoprodutos ou
          na participação de outros eventos realizados pela FORNECEDORA, ou
          outro acordo a ser formalizado com o CONTRATANTE, sem custos
          adicionais, taxas ou multas a serem aplicadas.
        </p>

        <p>
          9.3.1. O crédito a que se refere o item anterior poderá ser utilizado
          pelo CONTRATANTE no prazo de doze meses, contado da data de
          encerramento do estado de calamidade pública reconhecido pelo Decreto
          Legislativo nº 6, de 2020.
        </p>

        <p>
          9.4. Se qualquer das partes ficar temporariamente impedida de cumprir
          suas obrigações, no todo ou em parte, em consequência de caso fortuito
          ou de força maior, deverá comunicar o fato à outra PARTE no prazo de
          até 3 (três) dias e ratificar por escrito a comunicação em até 10
          (dez) dias, sob pena de decair do direito de invocar o disposto,
          informando os efeitos danosos do evento, as medidas que estiverem
          sendo tomadas e a previsão para regularização da situação.
        </p>

        <p>
          9.5. Em nenhuma hipótese será considerado como evento de força maior
          ou de caso fortuito a ocorrência de:
        </p>

        <ul>
          <li>
            Qualquer ação de qualquer autoridade pública que uma parte pudesse
            ter evitado se tivesse cumprido suas obrigações legais ou
            contratuais;
          </li>
        </ul>

        <ul>
          <li>
            Dificuldades econômicas ou financeiras de qualquer das partes.
          </li>
        </ul>

        <h3><strong>Cláusula Dez – Da Adesão</strong></h3>

        <p>
          10.1. Dando prosseguindo à imersão sem oposição ao presente contrato,
          que foi apresentado preliminarmente ao CONTRATANTE, o CONTRATANTE
          expressa ter lido e compreendido o presente contrato e os demais
          instrumentos jurídicos mencionados e disponibilizados no WEBSITE,
          tendo utilizado os mecanismos de atendimento para sanar e esclarecer
          todas suas dúvidas, e enfim estando de devidamente esclarecido e de
          acordo, na íntegra, em relação a todos os termos e condições que lhe
          foram apresentados, assume os direitos e obrigações estabelecidos, o
          que se dará por bem feito para todos os fins de direito.
        </p>

        <p>Maringá/PR.</p>

        <p><strong>WLR CURSOS E EVENTOS LTDA.</strong></p>

        <p>32.816.941/0001-94</p>
      </div>
    </div>
  </div>
</template>
